import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import tw from "twin.macro"

// Components
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"

const HomeHero = ({ imageData, altText }) => {
  const images = withArtDirection(getImage(imageData.homeHeroDesktop), [
    {
      media: "(max-width: 768px)",
      image: getImage(imageData.homeHeroMobile),
    },
  ])

  return (
    <section tw="max-md:(h-[calc(570px - 15vw)]) relative overflow-hidden md:(grid min-h-[26.25rem]) xl:min-h-[21.25rem]">
      <div tw="relative md:(absolute top-0 right-0 grid grid-cols-12 justify-items-end overflow-hidden)">
        <div tw="relative overflow-hidden md:(col-span-7 col-start-7) md-lg:col-start-6">
          <GatsbyImage
            tw="max-md:(h-[230px] ml-[150px]) md:(h-[470px]) md-lg:(w-[130%])"
            image={images}
            alt={altText}
            imgStyle={{
              width: "100%",
              height: "100%",
            }}
          />
          <div tw="bg-[linear-gradient(90deg, #FFF 35%, #FFF 40%, rgba(255, 255, 255, 0.00) 60%)] absolute left-0 bottom-0 w-screen h-full block md:(narc-home-hero-gradient top-0 h-[520px] w-[208px])"></div>
        </div>
      </div>
      <Container tw="max-md:(absolute top-0 mt-6)">
        <div tw="relative pt-5 py-10 md:(py-0 grid h-full grid-cols-12 overflow-hidden)">
          <div tw="md:(flex items-center justify-start col-span-8)">
            <div tw="md:(max-w-[37.5rem] pt-16 pb-10)">
              <h1 tw="max-md:w-[11.75rem] font-cocon narc-purple-red-gradient [background-clip: text] [-webkit-text-fill-color: transparent] leading-[0]">
                <span tw="font-avenir font-normal text-[1.25rem] leading-[125%] lg:(text-[2.1875rem] leading-[100%])">
                  It&lsquo;s time to address the high&#8209;sodium
                </span>
                <span tw="block pt-2 text-[2.5rem] leading-[100%] lg:(text-[3.125rem] leading-[130%])">
                  elephant in the room
                </span>
              </h1>
              <p tw="my-6 text-base font-normal md:my-4">
                Talk to your doctor about low-sodium* XYWAV.
              </p>
              <PrimaryCta
                url={"/narcolepsy/what-is-xywav/"}
                mdSmall
                width={tw`lg:w-[191px]`}
              >
                What is XYWAV
              </PrimaryCta>
              <p tw="mt-6 mb-2 text-base md:(font-normal max-w-[35.625rem])">
                XYWAV is thought to work during sleep to help improve symptoms
                of cataplexy and excessive daytime sleepiness (EDS) during the
                day.<sup>&dagger;</sup>
                <br />
              </p>
              <p tw="text-[12px] leading-4 pl-2">
                <span tw="-ml-2">*</span>XYWAV contains 131 mg of sodium at the
                maximum recommended nightly dose.
              </p>
              <p tw="text-[12px] leading-4 mb-6 pl-2">
                <sup tw="-ml-2">&dagger;</sup>The exact way XYWAV works for the
                treatment of cataplexy and EDS in patients with narcolepsy is
                unknown.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeHero

HomeHero.prototypes = {
  imageData: PropTypes.object.isRequired,
  altText: PropTypes.string,
}

HomeHero.defaultProps = {
  altText: "",
}
