import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Section from "@components/section"
import Anchor from "@components/Anchor"
import HomeHero from "@components/narcolepsy/HomeHero"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2 } from "@styles/ui"
import MyWavVideoSection from "@components/MyWavVideoSection"
import GradientText from "@components/gradientText"

// Assets
import coupon from "@images/narc-home/coupon.svg"
import savingsProgram from "@images/narc-home/savings-support.svg"
import standupChampionsLogo from "@images/logos/logo-sucs.svg"
import bloodPressure from "@images/narc-home/cardiovascular-risks-chart-blood-pressure.svg"
import bloodPressureMobile from "@images/narc-home/cardiovascular-risks-chart-blood-pressure-mobile.svg"
import heartAttack from "@images/narc-home/cardiovascular-risks-chart-heart-attack.svg"
import heartAttackMobile from "@images/narc-home/cardiovascular-risks-chart-heart-attack-mobile.svg"
import stroke from "@images/narc-home/cardiovascular-risks-chart-stroke.svg"
import strokeMobile from "@images/narc-home/cardiovascular-risks-chart-stroke-mobile.svg"

const NarcolepsyHomepage = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="A Treatment for 2 Narcolepsy Symptoms| XYWAV®"
        description="Learn about XYWAV® (calcium, magnesium, potassium, and sodium oxybates), an oral solution for patients with cataplexy and/or excessive daytime sleepiness in narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <HomeHero
        imageData={data}
        altText="A man discussing his sodium intake and his narcolepsy symptoms"
      />

      <section tw="py-[15px] lg:py-[25px] bg-lilac text-center">
        <Container>
          <p tw="text-base leading-[22px] max-w-[73rem] mx-auto">
            XYWAV is a prescription medicine used to treat the following
            symptoms in people 7 years of age or older with narcolepsy: sudden
            muscle weakness triggered by strong emotions (cataplexy) and
            excessive daytime sleepiness (EDS).
          </p>
        </Container>
      </section>

      <section tw="py-[30px] md:py-[50px]">
        <Container>
          <div tw="before:(gradientRed block relative w-[6.125rem] mb-[15px] h-1.5 rounded-md left-1/2 -translate-x-1/2) md:before:(rounded-md mb-[25px]) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-8 xl:mb-12 text-center`]}>
            Did you know that people with narcolepsy are at an increased risk of
            certain cardiovascular conditions?{" "}
          </h2>
          <div tw="lilac-gradient-vertical rounded-[25px] pt-[30px] px-[15px] md:(py-[10px] px-[50px] max-w-[68rem] mx-auto)">
            <div tw="mx-auto justify-center md:hidden">
              <GradientText otherStyles={tw`text-center`}>
                Compared to the general population, people with narcolepsy have:
              </GradientText>
            </div>
            <table tw="mt-6 w-full md:mt-0">
              <tr>
                <td tw="py-[10px] md:py-[15px]">
                  <div tw="mx-auto md:(mx-0) hidden md:block">
                    <GradientText otherStyles={tw`text-left`}>
                      Compared to the general population,
                      <br tw="hidden md:block" /> people with narcolepsy have:
                    </GradientText>
                  </div>
                </td>
                <td tw="py-[10px] md:(py-[15px])">
                  <div tw="mx-auto max-w-[80px] md:(max-w-[150px])">
                    <img src={bloodPressure} alt="" tw="hidden md:block" />
                    <img src={bloodPressureMobile} alt="" tw="md:hidden" />
                  </div>
                </td>
                <td tw="py-[10px] md:(py-[15px])">
                  <div tw="mx-auto max-w-[80px] md:(max-w-[150px])">
                    <img src={heartAttack} alt="" tw="hidden md:block" />
                    <img src={heartAttackMobile} alt="" tw="md:hidden" />
                  </div>
                </td>
                <td tw="py-[10px] flex md:(py-[15px])">
                  <div tw="mx-auto max-w-[80px] md:(max-w-[150px])">
                    <img src={stroke} alt="" tw="hidden md:block" />
                    <img src={strokeMobile} alt="" tw="md:hidden" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <ul tw="md:px-20 xl:px-32 2xl:px-[10.7rem] [&>li]:(text-[0.75rem] leading-4 mb-0.5)">
            <li>
              <sup>&Dagger;</sup>Odds ratio.
            </li>
            <li>
              <sup>§</sup>Based on an interview-based study of 320 people with
              narcolepsy to 1464 people without narcolepsy matched for age, sex,
              and body mass index.{" "}
            </li>
            <li>
              <sup>‖</sup>Based on an analysis of 9312 people with narcolepsy
              and 46,559 people without narcolepsy matched by age, sex,
              geographic region, and payer who had continuous insurance coverage
              between years of 2006 and 2010.
            </li>
            <li>
              <sup>¶</sup>Hazard ratio.
            </li>
            <li>
              <sup>#</sup>Based on an analysis of 34,562 people with narcolepsy
              and 100,405 people without narcolepsy matched by presence of
              relevant health conditions, including sleep apnea and diabetes.
            </li>
          </ul>
        </Container>
      </section>

      <Section>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-24 mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-16)"></div>
          <h2 tw="font-cocon font-bold text-[1.5625rem] leading-[120%] text-merlin mb-[25px] pb-0 xl:px-56">
            Learn more about how some risk factors for cardiovascular diseases,
            like excessive sodium intake, are modifiable.
          </h2>
          <PrimaryCta
            url={"/narcolepsy/living-with-narcolepsy/"}
            noInterstitial
            mdSmall
            width={tw`max-w-[18.75rem] md:max-w-[18.75rem]`}
          >
            NARCOLEPSY & RISK FACTORS
          </PrimaryCta>
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`}>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-24 mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-16)"></div>
          <h2 tw="font-cocon font-bold text-[1.5625rem] leading-[120%] text-merlin mb-[25px] pb-0">
            Consider sodium intake in medicine and your heart health
          </h2>
          <p tw="mb-[25px] text-base">
            XYWAV is the first and only low-sodium<sup>¶</sup> oxybate approved
            by the FDA to help treat cataplexy and/or EDS in narcolepsy for
            patients 7 years of age and older.
            <br />
            Learn more about why{" "}
            <strong>
              choosing XYWAV over high-sodium oxybates can help reduce your
              sodium intake
            </strong>
            , a modifiable factor for cardiovascular disease.
          </p>
          <PrimaryCta
            url={"/narcolepsy/lower-sodium-oxybate-treatment-option/"}
            noInterstitial
            mdSmall
            width={tw`max-w-[18.75rem] md:max-w-[18.75rem]`}
          >
            SODIUM AND XYWAV
          </PrimaryCta>
          <p tw="text-[0.75rem] leading-4 mt-[1.88rem] text-left md:(mt-[1.56rem] text-center) px-10">
            <sup tw="-ml-2">¶</sup>XYWAV contains 131&nbsp;mg of sodium at the
            maximum recommended nightly dose. At the same dose, high-sodium
            oxybates contain ~1640&nbsp;mg of sodium.
          </p>
        </Container>
      </Section>

      {/* Financial assistance */}
      <Section>
        <Container grid>
          <div tw="col-span-full lg:col-span-6 text-center mb-6">
            <img
              src={coupon}
              alt=""
              tw="mx-auto w-[9.38rem] lg:(w-[12.6rem] mb-[1.56rem])"
            />
            <div tw="xl:px-24">
              <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) lg:before:(mb-[25px])"></div>
              <h2 css={[Heading2, tw`mb-5 lg:mb-7 text-center`]}>
                The XYWAV Coupon Program
              </h2>
              <p tw="text-left mb-[15px] text-base lg:mb-[25px]">
                Pay as little as $5 for XYWAV.
              </p>
              <p tw="text-left text-[12px] leading-4 mt-[15px] lg:pr-32">
                Jazz Pharmaceuticals reserves the right to terminate or modify
                this program at any time with or without notice. Other&nbsp;
                <Anchor
                  link="https://jazzcares.com/patients/xywav/#termsandconditions"
                  isExternal
                  newTab
                  tw="inline"
                >
                  <nobr>Terms and Conditions</nobr>
                </Anchor>
                &nbsp;apply.
              </p>
            </div>
          </div>
          <div tw="col-span-full lg:col-span-6 text-center">
            <img
              src={savingsProgram}
              alt=""
              tw="mx-auto w-[9.38rem] lg:(w-[12.6rem] mb-[1.56rem])"
            />
            <div tw="xl:px-24">
              <div tw="before:(gradientRed block relative w-[124px] mb-5 h-1.5 rounded-md left-1/2 transform -translate-x-1/2) lg:before:(mb-6)"></div>
              <h2 css={[Heading2, tw`mb-[20px] text-center lg:(mb-[25px])`]}>
                Savings & Support From the Start
              </h2>
              <p tw="text-left mb-[15px] text-base lg:mb-[25px]">
                If your doctor prescribes XYWAV, JazzCares offers several
                support programs to help patients with the cost of treatment, so
                you can start XYWAV as soon as possible.
              </p>
              <div tw="text-center">
                <PrimaryCta
                  url="/narcolepsy/jazzcares-for-xywav/"
                  width={tw`md:w-[261px]`}
                >
                  SHOW ME HOW TO SAVE
                </PrimaryCta>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Standup champions */}
      <Section
        bgColour={tw`bg-lilac`}
        tw="pt-0 md:(flex items-center py-0) lg:(relative mx-auto)"
      >
        <Container grid>
          <div tw="order-2 col-span-full mt-[15px] md:(order-1 mt-0 max-w-[280px] col-span-5 h-full flex flex-col justify-center py-7) lg:(max-w-[unset] mt-0 col-span-5)">
            <img
              src={standupChampionsLogo}
              alt=""
              tw="w-full max-w-[250px] xl:max-w-[268px]"
            />
            <h2 css={[Heading2, tw`my-[15px] lg:(my-[25px])`]}>
              Hear from real patients taking XYWAV
            </h2>
            <p tw="mb-[15px] text-base leading-[22px] lg:(mb-[25px])">
              Watch videos from people living with narcolepsy and learn how they
              fit XYWAV treatment into their lives.
            </p>
            <div tw="text-center md:text-left">
              <PrimaryCta url="/patient-stories/" width={tw`md:w-[16.25rem]`}>
                Watch patient stories
              </PrimaryCta>
            </div>
          </div>
          <div tw="order-1 col-span-full md:(order-2 flex flex-col justify-end col-span-7 col-start-6)">
            <GatsbyImage
              image={getImage(data.collageMobile)}
              alt=""
              tw="relative z-10 w-screen -ml-4 md:hidden"
            />
            <GatsbyImage
              image={getImage(data.collageDesktop)}
              alt=""
              tw="hidden md:w-[35.8rem] md:(block -right-4 lg:right-[calc((100vw - 992px) / -2)]) xl:right-[calc((100vw - 1170px) / -2)] 2xl:right-[calc((100vw - 1344px) / -2)]"
            />
          </div>
        </Container>
      </Section>

      {/* Video */}
      <MyWavVideoSection
        thumbnail={data.thumbImage}
        theme="narcolepsy"
        newBgColor={tw`bg-white`}
        disclaimer
      />
    </Layout>
  )
}

export default NarcolepsyHomepage

export const query = graphql`
  query {
    homeHeroDesktop: file(
      relativePath: { eq: "narc-home/man-with-doctor-sodium-elephant.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 950, placeholder: NONE)
      }
    }
    homeHeroMobile: file(
      relativePath: { eq: "narc-home/elephant_standing_mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    collageDesktop: file(
      relativePath: { eq: "narc-home/xywav-patients-headshot.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1150, placeholder: NONE)
      }
    }
    collageMobile: file(
      relativePath: { eq: "narc-home/xywav-patients-headshot-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    thumbImage: file(
      relativePath: { eq: "video-thumbnails/mywav-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    bgImageSection: file(
      relativePath: { eq: "narc-home/woman-elephant-standing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "narc-home/woman-elephant-standing-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    bottleImage: file(relativePath: { eq: "what-is-xywav/bottle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
  }
`
